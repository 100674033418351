@use "../../../../utils.scss" as *;

.projects-archive-row {
  &__abstract {
    &.status-canceled {
      background: #FFF5F5;
    }
  }

  &__title {
    position: relative;
    width: 270px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: rgba(249, 249, 251, 0.2);
    border-radius: 4px;
    box-shadow: $box-shadow-light;

    cursor: pointer;
    pointer-events: all;

    &-container {
      display: flex;
    }

    &-section {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 16px;
        width: 14px;
        height: 10px;

        background: url(~assets/images/accordion.svg) center/contain
          no-repeat;
        flex-shrink: 0;
        transition: transform 0.5s;
        cursor: pointer;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__button-edit {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/button-edit.svg) rgba(242, 241, 237, 0.3) center/cover
      no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    &-status {
      padding: 4px 10px;
      max-width: 140px;
      min-width: 140px;
      width: 100%;
      height: 30px;
      font-weight: 700;
      text-align: center;
      border-radius: 42px;

      &-finished {
        color: $lightgreen;
        background: rgba(87, 192, 88, 0.1);
      }

      &-failed {
        color: $red-failed;
        background: rgba(255, 115, 115, 0.1);
      }

      &-canceled {
        color: #FF7373;
        background: rgba(255, 115, 115, 0.10);
      }
    }

    &-counter {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      text-align: right;

      &-activity {
        &-all {
          padding: 4px 8px 4px 4px;
          min-width: 70px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-right: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/actionBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }

        &-reviews {
          padding: 4px 8px 4px 4px;
          min-width: 60px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-left: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/messageBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }
      }

      .tooltips {
        pointer-events: none !important;
      }
    }

    &-last-activity {
      min-width: 70px;
      font-size: 14px;
      font-weight: 500;
    }

    &-city {
      max-width: 100px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 500;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__button-repeat {
    max-width: 150px;
    height: 40px;
    background: rgba(255, 255, 255, 0.4);
    border: none;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);

    .button-with-icon {
      &__icon {
        width: 22px;
        height: 22px;
      }
    }
  }

  &__reports {
    margin-top: 16px;
  }

  .tooltips {
    font-weight: 400;
    pointer-events: all;
  }

  .button {
    padding: 0 12px;
    max-width: max-content;
    width: 100%;
    height: 40px;
    border-radius: 16px;
  }
}
