@use "../../../utils.scss" as *;

.intro-markets {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  @media screen and (min-width: 360px) and (max-width: 766px) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
  }

  &__container {
    position: absolute;
    top: 10px;
    left: -100px;
    z-index: 0;
    max-width: 126px;
    width: 100%;

    @media screen and (max-width: 766px) {
      display: none;
    }

    &-message,
    &-human,
    &-like,
    &-eye {
      position: absolute;
      z-index: 1;
      fill: $white;
      border-radius: 10px;

      &.povedencheskie-faktory-na-ozon {
        background: linear-gradient(180deg, #4a87fc 0%, #004ff5 100%);
      }

      &.povedencheskie-faktory-na-wildberries {
        background: linear-gradient(347deg, #ab4ec3 22.62%, #fa3c96 95.12%);
      }

      &.povedencheskie-faktory-na-megamarket {
        background: linear-gradient(180deg, #a673ee 0%, #7e4cc4 100%);
      }

      &.nakrutka-podpischikov-telegram {
        background: linear-gradient(0deg, #1d93d2 0%, #38b0e3 100%);
      }

      &.nakrutka-podpischikov-vk {
        background: #07f;
      }

      &.povedencheskie-faktory-na-yandexmarket {
        background: linear-gradient(180deg, #ff7b57 0%, #f64a1d 100%);
      }

      &.nakrutka-pf-yandex {
        background: #fa3d1b;
      }

      &.reviews-on-yandexmaps,
      &.povedencheskie-faktory-na-yandexmaps {
        background: $white;
        fill: #ff4433;
      }
    }

    &-like {
      padding: 7px;
      top: 0;
      right: 16px;

      &.povedencheskie-faktory-na-obyavleniya,
      &.reviews-na-obyavleniya,
      &.views-na-obyavleniya {
        background: #ff6163;
      }

      &.nakrutka-pf-google {
        background: #E54335;
      }
    }

    &-eye {
      padding: 5px;
      top: 70px;
      right: 70px;
      align-self: flex-start;

      &.povedencheskie-faktory-na-obyavleniya,
      &.reviews-na-obyavleniya,
      &.views-na-obyavleniya {
        background: #a169f7;
      }

      &.nakrutka-pf-google {
        background: #F6B704;
      }
    }

    &-human {
      padding: 5px;
      top: 80px;
      right: -5px;

      &.povedencheskie-faktory-na-obyavleniya,
      &.reviews-na-obyavleniya,
      &.views-na-obyavleniya {
        background: #97cf26;
      }

      &.nakrutka-pf-google {
        background: #34A353;
      }
    }

    &-message {
      padding: 10px;
      order: 3;
      top: 250px;
      right: 12px;

      &.povedencheskie-faktory-na-obyavleniya,
      &.reviews-na-obyavleniya,
      &.views-na-obyavleniya {
        background: #0af;
      }

      &.nakrutka-pf-google {
        background: #4280EF;
      }
    }

    &-review {
      z-index: 1;
      max-width: 460px;
      width: 100%;
      min-height: 176px;
      height: 100%;

      @media screen and (max-width: 766px) {
        margin: 0 auto;
        min-height: 92px;
      }

      &.avito, &.yandex {
        background: url(~assets/images/dashboard/intro/info-avito.svg) center/contain
          no-repeat;
      }
    }
  }
}
