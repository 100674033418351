@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-slider {
  max-width: 480px;
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    gap: 8px;

    & span {
      font-size: 14px;
    }
  }

  &__marks {
    margin: 8px auto 0;
    display: flex;
    justify-content: center;
    gap: 24px;
  }

  .slider {
    max-width: 400px;
    width: 100%;
  }
}