@use "../../../utils.scss" as *;

.stopmodal {
  padding: 32px 40px;
  max-width: 570px;

  &__header {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 32px;

    &-icon {
      width: 97px;
      height: 97px;
      background: url(~assets/images/stop.svg) center no-repeat;
      background-size: cover;
    }

    &-message {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      color: $pink-dark;
    }

    &-comment {
      max-width: 220px;
      font-weight: 700;
      color: $gray-dark;
    }
  }

  &__info_points {
    padding: 16px;
    background: $gray;
    border-radius: 16px;
  }

  &__buttons {
    margin-top: 10px;
    padding-top: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-top: 2px solid $gray-disabled;
  }

  &__button {    
    &-cancel {
      max-width: 243px;
      width: 100%;
      background-color: $yellow-sub;
      border: 2px solid $yellow-sub;
    }

    &-ok {
      max-width: 243px;
      gap: 4px;
      font-weight: 700;
      color: $pink-dark;
      background-color: rgba(255, 115, 115, 0.1);
      border: none;

      .button-with-icon {        
        &__icon {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
