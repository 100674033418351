@use "../../../../utils.scss" as *;

.project-property-column {
  position: relative;
  max-width: 225px;

  &.finished {
    color: rgba(0, 0, 0.12, 0.5);
  }

  &__title {
    font-size: 14px;
    color: rgba(0, 0, 0.12, 0.5);
  }

  &__icon {
    margin-right: 4px;
    padding: 4px;
    width: 17px;
    height: 17px;
  }

  &__text {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;

    &.status-approved {
      font-weight: 700;
      color: $green;
    }

    &.status-rework,
    &.status-failed {
      font-weight: 700;
      color: $red-failed;
    }

    &.status-new,
    &.status-run,
    &.status-ready,
    &.status-replace {
      font-weight: 700;
    }

    &.status-finished {
      color: rgba(0, 0, 0.12, 0.5);
    }
  }

  &__timer {
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $pink-dark !important;

    &-icon {
      margin-right: 4px;
      padding: 5px;
      width: 16px;
      height: 16px;
      background: url(~assets/images/timerLeft.svg) center/contain no-repeat;
      background-size: 11px;
      flex-shrink: 0;
    }
  }
}
