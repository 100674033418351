@use "../../../../../utils.scss" as *;

.projects-active-report-row-wrapper {
  padding-left: 10px;
  border-left: 2px solid #e6e6e6;
}

.projects-active-report-row {
  position: relative;
  padding: 16px;
  border-radius: 8px;
  background-color: $gray;

  &:first-child {
    margin-top: 16px;
  }

  &__container-column {
    display: flex;
    align-items: center;
    gap: 64px;
  }

  &_ready {
    margin-bottom: 8px;
    background: rgba(255, 229, 0, 0.1);
  }
  &_new {
    margin-bottom: 8px;
  }
  &_run {
    margin-bottom: 8px;
  }
  &_replace,
  &_rework {
    margin-bottom: 8px;
    background: rgba(254, 0, 0, 0.05);
  }
  &_approved {
    margin-bottom: 8px;
    background: rgba(1, 190, 2, 0.05);
  }
  &_finished {
    margin-bottom: 8px;
    background: rgba(249, 249, 251, 0.4);
  }
  &_failed {
    margin-bottom: 8px;
    background: rgba(254, 0, 0, 0.05);
  }
  &_warn {
    background: linear-gradient(
        0deg,
        rgba(255, 146, 85, 0.1),
        rgba(255, 146, 85, 0.1)
      ),
      $gray;
  }

  &__report-data {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;

    &-column {
      margin-bottom: 4px;
      font-weight: 700;
    }
  }

  &__report-date-title {
    font-size: 14px;
  }

  &__report-date-value {
    font-size: 16px;
  }

  &__report-status-text {
    font-size: 12px;
    margin-top: 5px;
  }

  &__icon-warning {
    margin-left: 5px;
    width: 30px;
    height: 30px;
    background: center/contain no-repeat url(~assets/images/warning.svg);
    pointer-events: all;
  }

  .tooltips {
    display: flex;
    align-items: center;
  }

  &__report-check {
    margin-left: auto;
    padding: 0 16px;
    max-width: max-content;
    width: 100%;
    height: 45px;
    border-radius: 16px;
    pointer-events: all;

    &-finished,
    &-approved {
      text-decoration: underline;
      color: $lightgreen;
      background-color: transparent;
      border: none;
      text-align: right;

      &:hover {
        background-color: transparent;
      }
    }

    &-run,
    &-new,
    &-failed,
    &-rework,
    &-replace,
    &-cancel {
      color: $light-black;
      background-color: $gray-disabled;
      border: none;
      pointer-events: none;
    }
  }

  &__report-other-settings {
    margin-top: 16px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-top: 1px solid #d7e2e4;
  }

  &__report {
    &-raise-price {
      padding: 8px 24px;
      max-width: 225px;
      width: 100%;
      background: rgba(255, 255, 255, 1.4);
      border-radius: 16px;

      & p {
        color: $gray-dark;
        text-align: center;
        & span {
          font-weight: 700;
        }
      }

      &-button {
        max-width: 150px;
        height: 32px;
        gap: 4px;
  
        &:hover {
          background-color: $white;
        }

        &:disabled {
          background-color: $gray-disabled;
        }
      }

      &-info {
        display: flex;
        align-items: center;

        &-container {
          margin-top: 16px;
          padding-top: 16px;
          display: flex;
          align-items: center;
          gap: 16px;
          border-top: 1px solid #d7e2e4;
        }
      }
    }

    &-cancelling-button {
      padding: 5px;
      width: 32px;
      height: 32px;
      background: url(~assets/images/deleteGray.svg) $yellow-sub center
        no-repeat;
      border-radius: 50%;

      &:disabled {
        background-color: $gray-disabled;
        border-color: $gray-disabled;
        opacity: 0.6;
        cursor: default;
      }

      &.day-left {
        background: url(~assets/images/delete.svg) $yellow-sub center no-repeat;
      }
    }
  }
}
