.project-editing-title {
  display: flex;
  align-items: center;

  &__button-save {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/save-edit.svg) center/cover no-repeat;
    border-radius: 4px;
    flex-shrink: 0;
  }

  .textinput {
    width: 300px;
    height: 30px;
    padding: 0 16px;
    border-radius: 4px;
  }
}