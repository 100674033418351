@use "../../utils.scss" as *;

.popup {
  position: fixed;
  inset: 0;
  z-index: 11;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.05);

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;

  @media screen and (max-width: 766px) {
    background: rgba(0, 0, 0, 0.25);
  }

  &_opened {
    display: flex;
    opacity: 1;
    visibility: visible;
    overflow: scroll;
  }

  &__plate {
    margin: auto;
    padding: 26px 26px 38px;
    width: 100%;

    @media screen and (max-width: 766px) {
      margin: auto 16px;
      padding: 14px 14px 24px;
    }

    &.faq-in-report,
    &.faq-in-project {
      padding: 32px;
      max-width: $screenminwidth;
      border-radius: 8px;
    }
  }

  &__button-close {
    margin-left: auto;
    width: 24px;
    height: 24px;
    display: block;
    background: url(~assets/images/tagclosegray.svg) center/cover;

    @media screen and (max-width: 766px) {
      width: 22px;
      height: 22px;
    }
  }

  &__button-back {
    width: 40px;
    height: 32px;
    background: url(~assets/images/arrowLeft.svg) center/contain no-repeat;

    @media screen and (max-width: 766px) {
      width: 22px;
      height: 22px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;

      @media screen and (max-width: 766px) {
        font-size: 20px;
      }
    }

    &-subtitle {
      font-weight: 700;
    }
  }
}
