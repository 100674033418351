@use "../../../../../../utils.scss" as *;

.mode-selection {
  @media screen and (max-width: 766px) {
    padding-bottom: 24px;
    border-bottom: 2px solid $gray-disabled;
  }

  &__title {
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 19px;

    @media screen and (max-width: 766px) {
      text-align: center;
    }
  }

  &__container {
    padding: 4px;
    display: flex;
    align-items: center;
    gap: 8px;
    border: 2px solid $gray;
    border-radius: 36px;

    @media screen and (max-width: 766px) {
      height: auto;
      flex-wrap: wrap;
      justify-content: center;
      border: none;
    }
  }

  &__button {
    width: 100%;
    height: 54px;
    font-size: 18px;
    background-color: $gray;
    border-radius: 56px;

    @media screen and (max-width: 766px) {
      height: 42px;
      font-size: 16px;
    }

    &.active {
      font-weight: 700;
      background-color: #fff7ce;
      border: none;
    }
  }

  .button-with-icon {
    gap: 8px;

    @media screen and (max-width: 766px) {
      padding-left: 4px;
      padding-right: 4px;
      gap: 4px;
    }

    &__icon {
      width: 16px;
      height: 16px;

      @media screen and (max-width: 766px) {
        width: 12px;
        height: 12px;
      }
    }
  }
}
