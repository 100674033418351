@use "../../../../../utils.scss" as *;

.timer-section {
  &__container {
    padding: 8px 30px;
    max-width: 172px;
    width: 100%;
    background-color: rgba(255, 115, 115, 0.05);
    border-radius: 32px;
    color: $pink-dark;
  }

  &__text {
    font-weight: 700;
  }

  &__timer {
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &-img {
      padding: 4px;
      width: 23px;
      height: 23px;
      background: url(~assets/images/timerLeft.svg) center/contain no-repeat;
      background-size: 15px;
      flex-shrink: 0;
    }

    &-time {
      font-weight: 700;
    }
  }
}
