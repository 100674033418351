.projects-drafts-abstract-row {
  margin-bottom: 10px;
  padding: 5px 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FDFCF4;
  border-radius: 12px;
}
