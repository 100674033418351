@use "../../../../utils.scss" as *;

.projects-active-row {
  &__title {
    width: 270px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: rgba(249, 249, 251, 0.2);
    border-radius: 4px;
    box-shadow: $box-shadow-light;
    cursor: pointer;

    &-container {
      padding: 3px 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: $gray;
      border-radius: 12px;
    }

    &-section {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 12px;
        width: 14px;
        height: 10px;

        background: url(~assets/images/accordion.svg) center/contain
          no-repeat;
        flex-shrink: 0;
        transition: transform 0.5s;
        cursor: pointer;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__button-edit {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/button-edit.svg) rgba(242, 241, 237, 0.3) center/cover
      no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__button-config {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/cogicon.svg) rgba(242, 241, 237, 0.3) center/cover
      no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__no-config {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;

    &-status {
      padding: 4px 10px;
      max-width: 180px;
      width: 100%;
      height: 30px;
      font-weight: 700;
      color: $red-failed;
      text-align: center;
      background: rgba(255, 115, 115, 0.1);
      border-radius: 42px;        
    }

    &-counter {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      text-align: right;

      &-activity {
        &-all {
          padding: 4px 8px 4px 4px;
          min-width: 70px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-right: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/actionBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }

        &-reviews {
          padding: 4px 8px 4px 4px;
          min-width: 60px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          text-align: right;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-left: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/messageBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }
      }
    }

    &-strategy {
      width: 50px;
      font-size: 14px;
    }

    &-city {
      max-width: 100px;
      min-width: 100px;
      font-size: 14px;
      font-weight: 500;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-price {
      width: 65px;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__buttons {
    margin-left: auto;

    .tooltips {
      &__description {
        padding: 3px 10px;
        top: 0;
        left: -90%;
        transform: translateX(-90%);    
        width: 340px;
        white-space: normal;

        &::after {
          display: none;
        }
      }
    }
  }

  &__stop-icon {
    padding: 6px;
    width: 40px;
    height: 40px;
    background: url(~assets/images/stop.svg) center no-repeat;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .tooltips {
    font-weight: 400;
    pointer-events: all;
  }
}
