@use "../../../../../../utils.scss" as *;

.recommendation {
  @media screen and (max-width: 766px) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d7e2e4;
  }

  &-modal {
    @media screen and (max-width: 766px) {
      margin: 0;
      margin-top: auto;
      padding: 16px 8px 32px;
      border-radius: 16px 16px 0px 0px;
    }

    .popup {
      @media screen and (max-width: 766px) {
        &__button-close {
          display: none;
        }

        &__header-title {
          display: none;
        }
      }
    }

    .button {
      @media screen and (max-width: 766px) {
        padding: 0 24px;
        width: 100%;
        height: 44px;
        border-radius: 16px;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      padding: 0 8px;
      font-size: 24px;
      line-height: 110%;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 300;
    line-height: 120%;
    color: #939393;

    @media screen and (max-width: 766px) {
      padding: 8px 8px 0;
      font-size: 14px;
      font-weight: 700;
    }
  }

  &__link {
    font-size: 14px;
    line-height: 120%;
    color: #EEB010;

    @media screen and (max-width: 766px) {
      font-size: 14px;
    }
  }

  &__list {
    font-size: 14px;
    line-height: 120%;
    color: #939393;

    & li {
      margin-top: 6px;
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 120%;
      color: $black;

      &::before {
        margin-left: 6px;
        margin-right: 6px !important;
        min-width: 4px !important;
        width: 4px !important;
        height: 4px !important;
        background-color: $black;
      }

      &:last-child {
        margin-top: 0;
        margin-bottom: 0;
      }

      & span {
        display: contents;
        font-style: italic;
        font-weight: 500;
      }
    }
  }

  &__info-points {
    margin-top: 12px;
    padding: 16px;
    background: $gray;
    border-radius: 24px;

    @media screen and (max-width: 766px) {
      margin-top: 16px;
      padding: 14px 10px;
    }

    .info-point {
      &:first-child {
        padding-top: 8px;

        @media screen and (max-width: 766px) {
          padding-top: 0;
        }
      }

      &:nth-child(2) {
        padding-top: 16px;
        padding-bottom: 16px;
        align-items: start;

        @media screen and (max-width: 766px) {
          padding-top: 8px;
          padding-bottom: 12px;
        }
      }

      &:nth-child(3) {
        padding-top: 10px;
        padding-bottom: 10px;

        @media screen and (max-width: 766px) {
          padding-top: 8px;
          padding-bottom: 12px;
        }
      }

      &:nth-child(4) {
        padding-top: 16px;
        padding-bottom: 16px;

        @media screen and (max-width: 766px) {
          padding-top: 8px;
          padding-bottom: 0;
        }
      }

      &__icon {
        width: 40px;
        height: 40px;

        @media screen and (max-width: 766px) {
          width: 32px;
          height: 32px;
        }
      }

      &__text {
        font-size: 14px;
        line-height: 120%;
      }

      &__span {
        font-weight: 500;
      }
    }
  }
}
