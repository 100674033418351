@use "../../../../utils.scss" as *;

.info-section {
  margin-bottom: 16px;
  display: flex;

  &__title {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;

    &::before {
      content: "";
      display: block;
      background-color: $yellow-main;
      width: 5px;
      height: 5px;
      border-radius: 50%;
    }
  }
}

.comment-section {
  flex-direction: column;
}

.timer-section {
  justify-content: space-between;
  align-items: center;
}
