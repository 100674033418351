@use "../../../../../../utils.scss" as *;

.projects-active-report-row {
  &__warn-info {
    margin-top: 4px;
    padding: 24px 32px;
    position: relative;
    height: 131px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background-color: rgba(234, 67, 53, 0.05);
    border-radius: 16px;

    &-icon {
      position: absolute;
      left: 10px;
      top: 20px;
      height: 80px;
      width: 80px;
      background: url(~assets/images/warn-for-reports.svg) center/contain
        no-repeat;
    }

    &-lines-container {
      padding-left: 24px;
      font-size: 18px;
      line-height: 140%;
    }

    &-line {
      &-first {
        font-weight: 600;
        color: $red-failed;
      }
    }

    &-timer {
      display: flex;
      flex-direction: column;
      align-items: center;

      font-size: 32px;
      font-weight: 700;
      color: $pink-dark;

      & span {
        font-size: 14px;
      }
    }
  }
}
