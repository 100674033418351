@use "../../../utils.scss" as *;

.projects-drafts {
  &-row {
    margin-bottom: 16px;

    &:first-child {
      margin-top: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > div:first-child {
    .projects-drafts__row {
      margin-top: 0;
    }
  }

  &__row {
    margin-top: 30px;
    padding-bottom: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid $blue-dirty;
    cursor: pointer;

    &-total-project {
      display: flex;
      align-items: center;
    }

    &-title {
      font-size: 16px;
      font-weight: 700;
      color: $gray-dark;
    }

    &-accordion-icon {
      margin-right: 16px;
      width: 32px;
      height: 32px;

      background: url(~assets/images/arrowDownGray.svg) rgba(242, 241, 237, 0.3) center 12px no-repeat;
      border-radius: 8px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
      flex-shrink: 0;
      transition: transform 0.2s;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &-total-sum {
      padding-right: 16px;
      font-size: 18px;
      font-weight: 700;
      color: #ABABAB;

      &.other-color {
        color: $black;
      }
    }

    &-reports {
      height: 0;
      overflow: hidden;

      &.expanded {
        height: auto;
      }
    }
  }
}
