@use "../../../../../utils.scss" as *;

.comment-section {
  &__textarea {
    margin-top: 16px;
    padding: 16px 24px;
    width: 100%;
    height: 173px;

    border: none;
    background-color: $gray;
    outline-color: $yellow-main;
    border-radius: 8px;

    &::placeholder {
      font-family: "SF";
      font-size: 16px;
      font-weight: 400;
      opacity: 0.4;
    }
  }

  &__attention {
    padding: 8px 24px;

    display: flex;
    align-items: center;
    gap: 16px;

    background-color: rgba(234, 67, 53, 0.05);
    border-radius: 16px;

    &-img {
      width: 31px;
      height: 31px;
      background: url(~assets/images/messageattention.svg) center no-repeat;
      flex-shrink: 0;
    }

    & p {
      &:first-child {
        font-weight: 700;
        color: #ea4335;
      }
    }
  }
}
