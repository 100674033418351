@use "../../../../utils.scss" as *;

.projects-drafts-row {
  &__title {
    width: 270px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background: rgba(249, 249, 251, 0.2);
    border-radius: 4px;
    box-shadow: $box-shadow-light;

    cursor: pointer;
    pointer-events: all;

    &-container {
      display: flex;
    }

    &-section {
      display: flex;
      align-items: center;

      &-icon {
        margin-left: 16px;
        margin-right: 16px;
        width: 12px;
        height: 12px;

        background: url(~assets/images/accordion.svg) center/contain
          no-repeat;
        flex-shrink: 0;
        transition: transform 0.5s;
        cursor: pointer;

        &.expanded {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__button-edit {
    margin-left: 8px;
    padding: 4px;
    width: 30px;
    height: 30px;
    background: url(~assets/images/button-edit.svg) rgba(242, 241, 237, 0.3) center/cover
      no-repeat;
    background-size: 22px;
    border-radius: 4px;
    box-shadow: $box-shadow-light;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 24px;

    &-status {
      padding: 4px 10px;
      min-width: 150px;
      width: 100%;
      height: 30px;
      font-weight: 700;
      color: #FED400;
      background-color: $yellow-blond;
      border-radius: 42px;
      text-align: center;
    }

    &-counter {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      text-align: right;

      &-activity {        
        &-all {
          padding: 4px 8px 4px 4px;
          min-width: 70px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-right: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/actionBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }

        &-reviews {
          padding: 4px 8px 4px 4px;
          min-width: 60px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          font-size: 14px;
          font-weight: 700;
          text-align: right;
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(240, 240, 240, 1);
          border-left: none;
          border-radius: 4px;

          &-icon {
            width: 22px;
            height: 22px;
            background: url(~assets/images/activities/messageBlack.svg) center/contain
              no-repeat;
            flex-shrink: 0;
          }
        }
      }
    }
  }

  &__buttons {
    max-width: 230px;
    width: 100%;
    display: flex;
    gap: 8px;
  }

  &__delete-icon {
    width: 38px;
    height: 38px;
    background-color: rgba(234, 67, 53, 0.05);
    background-image: url(~assets/images/delete.svg);
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 37px;
    flex-shrink: 0;

    &:hover {
      opacity: 0.8;
    }
  }

  .tooltips {
    font-weight: 400;
    pointer-events: all;
  }

  .button {
    padding: 0 20px;
    max-width: 180px;
    width: 100%;
    height: 40px;
    border-radius: 16px;
  }
}
