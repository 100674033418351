@use "../../../utils.scss" as *;

.intro-performers {
  padding: 40px 40px 30px;
  max-width: 460px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 48px;
  box-shadow: $box-shadow-main;
  z-index: 1;

  @media screen and (max-width: 766px) {
    padding: 16px;
    min-width: 152px;
    width: 100%;
    min-height: 154px;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 24px;
  }

  &__people {
    position: relative;
    display: flex;

    @media screen and (max-width: 766px) {
      width: 89px;
      height: 56px;
    }

    &_img {
      margin-left: 4px;

      &-human1,
      &-human2,
      &-human3,
      &-check {
        position: absolute;
        top: -25px;
        width: 54px;
        height: 54px;

        @media screen and (max-width: 766px) {
          top: 0;
          width: 28px;
          height: 28px;
        }
      }

      &-human1 {
        left: 0;
      }

      &-human2 {
        left: 38px;

        @media screen and (max-width: 766px) {
          left: 20px;
        }
      }

      &-human3 {
        left: 78px;

        @media screen and (max-width: 766px) {
          left: 40px;
        }
      }

      &-check {
        left: 116px;
        background: url(~assets/images/dashboard/check.svg) center/contain
          no-repeat;

        @media screen and (max-width: 766px) {
          left: 60px;
        }
      }
    }

    &_description {
      margin-left: auto;

      @media screen and (max-width: 766px) {
        margin-left: 0;
      }

      &-title {
        font-weight: 700;
        line-height: 120%;
        text-align: center;

        @media screen and (max-width: 766px) {
          font-size: 24px;
          line-height: 70%;
          text-align: left;

          & span {
            font-size: 16px;
          }
        }
      }

      &-text {
        line-height: 120%;
        text-align: center;

        @media screen and (max-width: 766px) {
          margin-top: 6px;
          font-size: 12px;
          font-weight: 300;
          color: #939393;
          text-align: left;
        }
      }
    }
  }
}
