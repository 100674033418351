@use "../utils.scss" as *;

.popupbox {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;

  padding: 8px 16px;
  max-width: 345px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;

  background: linear-gradient(0deg, rgba(1, 190, 2, 0.10) 0%, rgba(1, 190, 2, 0.10) 100%), $white;
  box-shadow: $box-shadow-main;
  visibility: hidden;

  &_opened {
    visibility: visible;
  }
}
