@use "../../../../utils.scss" as *;

.faq-report-modal {
  padding: 32px;

  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: $gray;
  border-top: 1px solid $gray-disabled;

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: $gray-dark;
  }

  &__faqs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &-question {
      padding: 8px 18px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      background-color: $gray-disabled;
      border-radius: 16px;

      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &-img {
        width: 18px;
        height: 18px;
        background: url(~assets/images/plus.svg) center no-repeat;
        background-size: 18px;
        flex-shrink: 0;

        &.active {
          background: url(~assets/images/cross.svg) center no-repeat;
          background-size: 15px;
        }
      }

      &.active {
        color: $gray-dark;
      }
    }
  }
}
