@use "../../../../../utils.scss" as *;

.market-step2-mobile {
  padding: 32px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__distribution {
    padding: 0 8px 8px;
    display: flex;
    align-items: center;
    gap: 8px;

    &-icon {
      width: 32px;
      height: 32px;
      border-radius: 8px;
      box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.1);
      flex-shrink: 0;
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
    }
  }

  &__recommendation {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-title {
      padding: 0 8px;
      font-weight: 700;
      line-height: 110%;
    }

    &-description {
      padding: 14px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 6px;
      background: $white;
      border-radius: 16px;
      box-shadow: $box-shadow-main;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 120%;
    }

    &-button {
      padding: 0 12px;
      max-width: 100px;
      height: 34px;
      font-size: 14px;
      font-weight: 500;
      background-color: $gray;
      border: 2px solid $gray;
      border-radius: 12px;
      box-shadow: $box-shadow-main;
    }
  }

  &__settings {
    display: none;

    &.fixed-bottom {
      display: block;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 64px;
      z-index: 6;
    }
  }

  &__budget-slider {
    &-container {
      padding: 16px;
      border-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-color: $white;
      box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.12);
    }

    &-action {
      padding: 12px 16px;
      background-color: $gray;
      border-radius: 16px;
    }
  }

  &__project {
    &-price-info {
      font-size: 22px;
      font-weight: 700;
    }

    &-info {
      display: flex;
      flex-direction: column;

      &_action {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        &-icon {
          width: 18px;
          height: 18px;
          background: url(~assets/images/activities/actionBlack.svg) center/contain no-repeat;
        }
      }

      &_reviews {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        &-icon {
          width: 18px;
          height: 18px;
          background: url(~assets/images/activities/messageBlack.svg) center/contain no-repeat;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  &__deadline {
    display: block;

    &-title {
      margin-top: 8px;
      margin-bottom: 8px;

      display: flex;
      align-items: center;
      gap: 6px;

      font-size: 14px;

      &::before {
        content: "";
        display: block;
        background-color: $yellow-main;
        width: 5px;
        height: 5px;
        border-radius: 50%;
      }
    }
  }
}
