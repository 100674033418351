@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-rise {
  margin-top: 10px;
  margin-bottom: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $blue-dirty;

  &__raise-price {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__continue {
    min-width: 270px;
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;
  }

  &__update {
    padding: 0 16px;
    min-width: 270px;
  }

  .button {
    padding: 0 16px;
    max-width: 270px;
    width: 100%;
    height: 38px;
  }
}