@use "../../../../../../utils.scss" as *;

.deadline {
  border: 1px solid transparent;
  transition: border-color $smallduration;
  cursor: pointer;

  @media screen and (max-width: 766px) {
    margin-bottom: 4px;
    margin-top: 4px;
    text-align: center;
    background-color: $white;
    box-shadow: $box-shadow-light;
    border-radius: 8px;
  }

  &.active {
    border-color: $yellow-main;
    border-radius: 8px;
    box-shadow: $box-shadow-light;
  }

  &-container {
    padding: 8px 6px 16px;
    max-width: 138px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    border-radius: 8px;

    @media screen and (max-width: 766px) {
      padding: 6px 2px;
      max-width: none;
      flex-direction: row;
      justify-content: space-between;
    }

    &:hover {
      box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
    }

    &:active {
      background-color: $gray;
      box-shadow: none;
    }
  }

  &-days {
    margin-bottom: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    max-width: 125px;
    width: 100%;
    height: 33px;

    background-color: $gray;
    border-radius: 16px;

    font-size: 18px;
    line-height: 140%;
    text-align: center;

    @media screen and (max-width: 766px) {
      margin-bottom: 0;
      margin-right: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
      max-width: none;
      height: 28px;
      font-size: 14px;
    }
  }

  &-content {
    margin-bottom: 4px;
    text-align: center;

    @media screen and (max-width: 766px) {
      margin-bottom: 0;
    }
  }

  &-emoji {
    font-size: 24px;
  }

  &-title {
    font-weight: 700;
    line-height: 140%;

    @media screen and (max-width: 766px) {
      font-size: 12px;
      font-weight: 500;
    }
  }

  &-text {
    margin-bottom: 10px;
    font-size: 12px;
    color: $light-black;
    text-align: center;
  }

  &-button {
    padding: 4px 9px;
    max-width: 105px;
    width: 100%;
    height: 28px;

    gap: 4px;

    font-size: 14px;
    background-color: $yellow-sub;
    border-radius: 16px;

    @media screen and (max-width: 766px) {
      margin-left: 4px;
      max-width: none;
      min-width: 100px;
    }

    &.active {
      background-color: #fff7ce;
      border: none;
    }
  }

  .button-with-icon {    
    &__icon {
      width: 16px;
      height: 17px;
    }
  }
}
