@use "../../../utils.scss" as *;

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 26px;
    background-color: $light-black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 18px;
    border-radius: 6px;

    &-back {
      background-image: url(~assets/images/arrowLeft.svg);
    }

    &-next {
      background-image: url(~assets/images/arrowLeft.svg);
      transform: rotate(180deg);
    }

    &.disabled {
      background-color: $gray-disabled;
      cursor: default;
    }
  }

  &__numbers {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__dots {
    width: 28px;
    height: 26px;
    font-size: 12px;
    text-align: center;
    line-height: 230%;
    background: $gray;
    border-radius: 4px;
  }

  &__number {
    padding: 0 6px;
    min-width: 28px;
    height: 26px;
    font-size: 12px;
    background: $gray;
    border-radius: 4px;
    transition: all 0.2s ease;
    cursor: pointer;

    &:hover {
      background: linear-gradient(0deg, #efece1 0%, #efece1 100%), $yellow-sub;
    }

    &.active {
      font-weight: 700;
      background: $yellow-main;

      &:hover {
        background: linear-gradient(0deg, #ebc401 0%, #ebc401 100%), #fed400;
      }
    }
  }

  &__show-more {
    background-color: $yellow-sub;
    border: 2px solid $yellow-sub;
  }
}
