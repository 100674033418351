@use "../../../../../utils.scss" as *;

.projects-active-row {
  &__error-block {
    margin-top: 16px;
    margin-bottom: 8px;
    padding: 11px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &_container {
      padding: 24px 44px;
      max-width: 470px;
      width: 100%;
      max-height: 110px;
      height: 100%;

      display: flex;
      align-items: center;

      background-color: rgba(234, 67, 53, 0.05);
      border-radius: 16px;
    }

    &-buttons {
      display: flex;
      gap: 16px;

      &-archive {
        min-width: 256px;
        width: 100%;
        background-color: $yellow-sub;
        border: 2px solid $yellow-sub;
      }

      &-config {
        min-width: 256px;
        width: 100%;

        .button-with-icon {
          &__icon {
            width: 22px !important;
            height: 23px !important;
          }
        }
      }
    }

    &_link {
      min-width: 264px;
      width: 100%;

      .button {
        width: 100%;
        height: 54px;
        border-radius: 16px;
      }
    }
  }

  &__error-icon {
    margin-right: 24px;
    width: 31px;
    height: 31px;
    background: url(~assets/images/messageattention.svg) center no-repeat;
    background-size: contain;
    flex-shrink: 0;
  }

  &__error-description {
    font-size: 18px;
    font-weight: 600;
    color: $red-failed;

    & span {
      font-size: 16px;
      font-weight: 400;
      color: $black;
    }
  }
}
