@use "../../../../../utils.scss" as *;

.faq-modal {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 750px 330px;
    gap: 24px;

    &-answers {
      padding: 24px 32px;
      border-radius: 8px;
      background: #f9f9fb;

      display: flex;
      flex-direction: column;
      gap: 16px;

      & h3 {
        margin-bottom: 8px;
        font-size: 18px;
        color: #ababab;
        font-weight: 400;

        & span {
          font-weight: 700;
        }
      }

      & p {
        font-size: 18px;
        line-height: 25px;
        & span {
          font-weight: 700;
        }

        & li {
          &::before {
            margin-top: 9px;
            background-color: $black;
          }
        }

        & i {
          font-size: 16px;
          color: $gray-dark;
        }
      }

      & ol {
        & li {
          margin-left: 20px;
          display: list-item;
          list-style-type: decimal;

          &::before {
            margin-right: 2px;
          }

          &::marker {
            font-weight: 700;
          }
        }
      }
    }

    &-important {
      padding: 24px;
      background-color: rgba(254, 212, 0, 0.1);
      border-radius: 16px;

      &-item {
        & ol {
          & li {
            margin-left: 24px;
            display: list-item;
            list-style-type: decimal;

            &::before {
              margin-right: 2px;
            }

            &::marker {
              font-weight: 700;
            }
          }
        }
      }
    }

    .advice {
      background: rgba(254, 212, 0, 0.05);
    }

    .advice-title {
      color: #c9c4ad;
    }

    &-questions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .button {
        width: 100%;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
