@use "../../utils.scss" as *;

.intro-with-points {
  @media screen and (max-width: 766px) {
    margin: 24px 8px;
  }

  &__comment {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%;

    @media screen and (max-width: 766px) {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      color: #747474;
      text-align: center;
    }
  }

  &__list {
    & li {
      margin-bottom: 12px;
      align-items: center;
      gap: 10px;
      line-height: 120%;

      @media screen and (max-width: 766px) {
        margin-bottom: 8px;
        font-size: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        margin-top: 0;
        width: 16px;
        height: 16px;
        background: url(~assets/images/dashboard/plus.svg) center no-repeat;
        flex-shrink: 0;

        @media screen and (max-width: 766px) {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
}
