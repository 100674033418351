@use "../../../../utils.scss" as *;

.projects-plate {
  padding-top: 50px;
  padding-bottom: 50px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-title {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 700;
      color: rgba(30, 30, 30, 0.5);
    }

    &-comment {
      font-weight: 500;
      color: rgba(30, 30, 30, 0.5);
    }

    .button-with-icon {
      margin-top: 24px;
      max-width: 250px;

      &__icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
