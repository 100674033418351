@use "../../../../utils.scss" as *;

.projects {
  &__filter {
    position: relative;
    max-width: 255px;
    min-width: 255px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    &-select {
      padding: 8px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      background: $gray;
      border: 1px solid $gray-disabled;
      border-radius: 12px;
      cursor: pointer;

      &.open {
        padding-left: 15px;
        padding-right: 15px;
        color: #c7c7c7;
        border: 1px solid $gray;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.open p {
        text-align: left;
      }

      & p {
        width: 100%;
        text-align: center;
      }
    }

    &-icon {
      width: 18px;
      height: 18px;
      background: url(~assets/images/filter.svg) center no-repeat;
      flex-shrink: 0;
    }

    &-arrow {
      width: 18px;
      height: 18px;
      background: url(~assets/images/accordion.svg) center no-repeat;
      background-size: 13px;
      flex-shrink: 0;
      transform: rotate(180deg);
    }

    &-dropdown {
      position: absolute;
      top: 36px;
      left: 0;
      z-index: 10;
      max-width: 255px;
      width: 100%;
      background: $gray;
      border-radius: 0 0 12px 12px;
    }

    &-option {
      padding: 10px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &:hover {
        background: linear-gradient(
          89deg,
          #f1f1f3 0%,
          #eaeaea 45%,
          #f1f1f3 100%
        );

        &:last-child {
          border-radius: 0 0 12px 12px;
        }
      }

      &.active {
        color: #c7c7c7;
      }

      &-count {
        padding: 2px 12px;
        min-width: 40px;
        width: max-content;
        font-size: 12px;
        color: $gray-dark;
        text-align: center;
        background: $gray-disabled;
        border-radius: 4px;
      }
    }
  }
}
