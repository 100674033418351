@use "../../../../../utils.scss" as *;

.market-step2 {
  padding: 32px 56px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  &__distribution {
    max-width: 504px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 32px;

    .faqed {
      justify-content: space-between;

      &__children-container {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      line-height: 110%;
    }
  }

  &__settings {
    &-container {
      padding-left: 11px;
      padding-right: 11px;
    }
  }

  &__budget-slider {
    &-container {
      margin-bottom: 24px;
      padding: 16px 18px 32px;

      border-radius: 16px;
      box-shadow: $box-shadow-main;
    }

    &-total-cost {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
    }

    &-action {
      margin-bottom: 16px;
      padding: 16px 24px;
      background-color: $gray;
      border-radius: 16px;
    }
  }

  &__project {
    &-price-info {
      font-size: 32px;
      font-weight: 700;
      line-height: 35px;
    }

    &-info {
      &_action {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        &-icon {
          width: 18px;
          height: 18px;
        }
      }

      &_reviews {
        margin-top: 10px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;

        &-icon {
          width: 18px;
          height: 18px;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  &__deadline {
    &-title {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;

      &::before {
        content: "";
        width: 5px;
        height: 5px;
        display: block;
        background-color: $yellow-main;
        border-radius: 50%;
      }
    }

    &-choice {
      display: flex;
      justify-content: space-between;
    }
  }

  &__create-button {
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    gap: 2px;

    @media screen and (max-width: 766px) {
      margin-bottom: 0;
      padding: 0;
      height: 44px;
      border-radius: 12px;
      background: $gray-disabled;
      border: 1px solid $gray-disabled;
    }

    .button-with-icon {
      &__icon {
        width: 22px;
        height: 22px;
        transform: rotate(180deg);
      }
    }
  }
}
