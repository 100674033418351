@use "../../../../../../utils.scss" as *;

.projects-active-report-row {
  &__container {
    margin-left: 10px;
    padding: 8px 0 8px 10px;
    border-left: 2px solid #E6E6E6;

    &.opacity {
      opacity: 0.4;
    }
  }

  &__chains {
    padding: 16px 10px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 8px;

    background-color: $gray;
    border-radius: 16px;

    &-item {
      padding: 8px 18px;

      width: fit-content;
      height: 28px;

      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);

      border-radius: 16px;
      background-color: $white;

      display: flex;
      justify-content: center;
      align-items: center;

      &.with-border {
        border: 1px solid $yellow-main;
      }

      &-img {
        margin-right: 8px;

        width: 15px;
        height: 15px;
      }
    }
    
    &-dash {
      height: 3px;
      width: 16px;
      background: url(~assets/images/dash.svg) center no-repeat;
      background-size: 16px 3px;
    }
  }
}