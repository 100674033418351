@use "../../../../../../../utils.scss" as *;

.projects-active-report-auction-visibility {
  padding: 7px 14px;
  position: relative;
  max-width: 200px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $blue-dirty;
  border-radius: 16px;

  &__title {
    margin-top: 6px;
    font-size: 13px;
    color: $light-black;
    text-align: center;
  }
}
