@use "../../utils.scss" as *;

.projects {
  margin: 0 auto 70px;
  min-height: 100%;
  max-width: $screenminwidth;
  width: 100%;

  &__header {
    padding-top: 36px;
    padding-bottom: 24px;

    display: flex;
    justify-content: space-between;

    &-title {
      font-size: 32px;
      font-weight: 700;
    }

    &-add-button {
      max-width: 210px;

      &:hover {
        background-color: $white;
      }
    }

    .button-with-icon {
      &__icon {
        width: 18px;
        height: 18px;
      }
    }
  }

  &__tabs-container {
    width: 100%;
  }

  &__tabs {
    max-width: 720px;
    display: flex;
    background-color: $gray-disabled;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &__tab {
    flex: 1;
    padding: 16px;
    color: #939393;
    cursor: pointer;

    &-active {
      font-weight: 700;
      color: $black;
      background-color: $white;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &-content {
      padding: 32px 16px;
      width: 100%;

      background-color: $white;
      border-top-right-radius: 32px;
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      box-shadow: $box-shadow-main;
    }
  }
}
