@use "../../../../utils.scss" as *;

@keyframes pulse {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.projects-progress-indicator {
  display: flex;
  gap: 2px;

  &__part {
    flex-grow: 1;
    width: 16px;
    height: 10px;
    background-color: $gray;
    border-radius: 4px;
    overflow: hidden;

    &_running::before {
      display: block;
      height: 100%;
      position: relative;      
      content: "";
      background-color: $green-success;
      animation: pulse 2s infinite steps(24, end);
    }

    &_done {
      background-color: $lightgreen;
    }

    &_failed {
      background-color: $red-failed;

      &-empty {
        background-color: rgba(234, 67, 53, 0.05);
      }
    }
  }
}
