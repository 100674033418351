@use "../../../utils.scss" as *;

.report-modal {
  padding: 0;
  max-width: $screenminwidth;
  border-radius: 8px;

  &__container {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__row {
    padding: 16px 24px;
    background-color: $gray;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 766px) {
      flex-wrap: wrap;
      gap: 14px;
    }

    .project-property-column {
      width: -moz-fit-content;
      width: fit-content;

      &__title {
        margin-bottom: 8px;
        color: $light-black;
      }
    }

    &-activity-type {
      font-weight: 700;
    }
  }

  &__info {
    padding: 16px;
    border-radius: 8px;
    background-color: $white;
    box-shadow: $box-shadow-main;

    &-answer {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__subplate {
    padding: 20px;
    background-color: $gray;
    border-radius: 8px;

    h1,
    h2,
    h3 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 16px;
    }
    p:last-child {
      margin-bottom: 0;
    }

    &-empty-answer {
      color: $pink-dark !important;
      font-weight: 700;
      text-align: center;
    }
  }

  &__buttons {
    &-container {
      display: flex;
      gap: 16px;
    }

    &-reject {
      max-width: 545px;
      width: 100%;
      border: none;

      &:hover {
        background-color: $yellow-main !important;
        opacity: 0.8;
      }
    }

    &-approve {
      max-width: 545px;
      width: 100%;
      font-weight: 700;
      color: $green;
      background-color: rgba(87, 192, 88, 0.2);
      border: none;

      &:hover:not(:disabled) {
        background-color: rgba(87, 192, 88, 0.2) !important;
        opacity: 0.8;
      }

      &:disabled {
        background-color: $gray-disabled;
        color: $gray-dark;
      }
    }

    &-replace {
      max-width: 545px;
      width: 100%;
      font-weight: 700;
      color: $pink-dark;
      background-color: rgba(234, 67, 53, 0.1);
      border: none;

      &:hover {
        background-color: rgba(234, 67, 53, 0.1) !important;
        opacity: 0.8;
      }
    }
  }

  &__warning {
    padding: 8px 24px;

    display: flex;
    align-items: center;
    gap: 16px;

    background: rgba(254, 212, 0, 0.05);
    border: 1px solid $yellow-main;
    border-radius: 16px;

    &-img {
      width: 31px;
      height: 31px;
      background: url(~assets/images/warning.svg) center no-repeat;
      flex-shrink: 0;
    }

    &-text {
      font-weight: 600;

      & span {
        font-weight: 400;
      }
    }
  }

  &__small-comment {
    font-style: italic;
  }

  .popup__header {
    padding: 32px 32px 0;
  }
}
