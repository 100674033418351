@use "../../../../../utils.scss" as *;

.projects-active-row {
  &__wait-info {
    margin-top: 8px;
    margin-bottom: 8px;
    padding: 14px 32px;

    position: relative;
    height: 200px;
    
    background-color: $gray;
    border-radius: 8px;

    &:first-child {
      margin-top: 16px;
    }

    &.dependent-height {
      height: 145px;
    }

    &-icon {
      position: absolute;
      left: 32px;
      top: 25%;
      height: 83px;
      width: 83px;

      background: url(~assets/images/wait-tasks.svg) center/contain no-repeat;
      z-index: 0;
    }

    &-lines-container {
      position: absolute;
      margin-left: 48px;
    }

    &-first-line {
      font-weight: 700;
    }
  }
}
