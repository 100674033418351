@use "../../../utils.scss" as *;

.tooltips {
  position: relative;
  pointer-events: none;

  &__description {
    position: absolute;
    top: 0;
    left: 100%;

    padding: 5px 10px;

    font-size: 14px;
    text-align: left;
    text-wrap: nowrap;
    white-space: nowrap;
    
    border-radius: 16px;
    background-color: $white;
    box-shadow: $box-shadow-main;
    z-index: 1;

    &::after { 
      content: '';

      width: 13px;
      height: 9px;

      position: absolute;
      top: 36%;
      left: -8px;
      transform: translateX(-50%);

      background: url(~assets/images/triangle.svg) center no-repeat;
      transform: rotate(90deg);
    }
  }
}
